import React, { Component } from "react";
import { inject } from "mobx-react";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";
import ChangePasswordComponent from "./ChangePasswordComponent";
import { TOKEN_KEY } from "../../configs/constants";

@inject("UserStore")
class ChangePasswordContainer extends Component {
  state = {
    password: ""
  };

  constructor(props) {
    super(props);  
    this.onChange = this.onChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    localStorage.removeItem(TOKEN_KEY);
  } 

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async changePassword() {
    this.setState({
      isLoading: true,
    });
    const { UserStore, history } = this.props;
    const { newPassword } = this.state;
    
    const data = await UserStore.changePassword(this.props.location.search.substring(1), newPassword);
    if (data != null && data !== undefined && data === true) {
      AlertComponent.success("Senha alterada.");
      setTimeout(async () => {
        history.push("/home");
      }, 500);
    } else {
      AlertComponent.error("Falha ao alterar sua senha.");
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {    
    const { isLoading } = this.state;
    return (
      <>
        {(isLoading && <LoadingComponent />) || (
          <ChangePasswordComponent
            {...this.state}
            onChange={this.onChange}
            changePassword={this.changePassword}
          />
        )}
      </>
    );
  }
}

export default ChangePasswordContainer;
