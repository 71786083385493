import React from "react";
import { inject } from "mobx-react";

import HangtagComponent from "./HangtagComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { PAGES_HANGTAGS_TAB } from "~/configs/constants";
import { valueCurrencyToInt }  from "../../helpers/monetary"
import { getVarFromUrl } from "../../helpers/utils";

@inject("HangtagStore", "DeviceStore", "ProductStore", "PageStore")
class HangtagContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hangtag: {},
      products:[],
    };
  }

  async componentDidMount() {
    await this.getHangtag();
  }

  getHangtag = async () => {
    this.setState({
      isLoading: true,
    });

    const { HangtagStore, PageStore, history } = this.props;
    const deviceId = getVarFromUrl("deviceId");
    const pageId = getVarFromUrl("pageId");
    const hangtagId = getVarFromUrl("hangtagId");
    
    const hasDevice = deviceId !== undefined && parseInt(deviceId) > 0;
    if(!hasDevice){
      history.push("/");
      return;
    }

    let hangtag = {};
    hangtag.deviceId = deviceId;
    hangtag.pageId = pageId;
    if (hangtagId !== undefined && parseInt(hangtagId) > 0){
      hangtag = await HangtagStore.getHangtag(hangtagId);
      hangtag.customDescription = hangtag?.product.description;
      if (hangtag.isOffer)
        hangtag.product.originalValueStr = (hangtag?.product.originalValue / 100.00).toFixed(2);
      else hangtag.product.originalValueStr = 0.00.toFixed(2);
    }   
    
    var page = await PageStore.getPage(pageId);
      
    let productResult = await this.getProduct(hangtag, deviceId);
    let product = productResult.product;
    let products = productResult.products;

    this.setState({
      hangtag,
      isLoading: false,
      page,     
      product,
      products
    });
  };

  makeProductItem = (product) => {
    return {
      id: product.productId,
      name: product.name + " " + product.code,
      label: product.name + " " + product.code,
      productId: product.productId,
      code: product.code,
      valueStr: product.valueStr
    };
  }

  getProduct = async (hangtag, deviceId) => {
    let products = await this.getProducts("", deviceId);
    let result = {};
    if (hangtag.product != null && hangtag.product.productId > 0)
      hangtag.product = this.makeProductItem(hangtag.product);
    else 
      result.product = products[0];
    
    if (!this.isProductList(result.product, products))
      products.push(result.product);
    
    result.products = products;
    return result;
  }

  getProducts = async (filter, deviceId) => {
    if (filter === "Nenhum ") filter = "";
    const { ProductStore } = this.props
    let products = [];
    let productsData = await ProductStore.searchProductsByDevice(filter, deviceId);
    for(let i = 0; i < productsData.length; i++) {
      products.push(this.makeProductItem(productsData[i]));
    }
    return products;
  };

  goDevice = () => {
    const { history } = this.props;
    history.push(`page?deviceId=${getVarFromUrl("deviceId")}&pageId=${getVarFromUrl("pageId")}&tab=${PAGES_HANGTAGS_TAB}`);
  };

  maintainHangtag = async () => {
    this.setState({
      isLoading: true
    });

    const { hangtag} = this.state;
    const { HangtagStore } = this.props;

    const result = await HangtagStore.update(hangtag);
    if (result && result?.tagId > 0){
      AlertComponent.success("Etiqueta salva com sucesso");
      this.goDevice();
    } else {
      AlertComponent.error("Falha ao salvar etiqueta");
    }

    this.setState({
      isLoading: false
    });
  };

  onChangeOriginalValue = (e) => {
    const { hangtag } = this.state;
    hangtag.product.originalValue = valueCurrencyToInt(e.target.value);
    this.setState({
      hangtag
    });
  };
  
  onChange = (e) => {
    const { hangtag } = this.state;
    const updatedHangtag = {
      ...hangtag,
      [e.target.name]: e.target.value
    }

    this.setState({
      hangtag: updatedHangtag,
    });
  };
 
  onInputChangeProduct = async (event, value) => {
    if (event !== null) {
      try { 
          if (event.type === "change") {
            this.setState({
              productIsLoading: true,
            });
            const { product } = this.state;
            let products = await this.getProducts(value, getVarFromUrl("deviceId"));
      
            if (!this.isProductList(product, products))
              products.push(product);
      
            this.setState({
              products,
              productIsLoading: false
            });
          }
          else {
            const { products, product } = this.state;
            let productSelected = product;
            for (var i = 0; i < products.length; i++){
              if (products[i].name === value){
                productSelected = products[i];
                break;
              }
            }
            this.onChangeProduct(productSelected);            
          }          
        }
      catch { }
    }
  };

  isProductList = (product, products) => {
    if (product === undefined || product === null || product.productId === undefined) return false;
    for (var i = 0; i < products.length; i++){
      if (products[i].productId === product.productId){
        return true;
      }
    }; 
    return false;
  };

  onChangeProduct = (product) => {
    const { hangtag } = this.state;
    const updatedHangtag = {
      ...hangtag,
      product,
      productId: product?.productId,
    }

    this.setState({
      hangtag: updatedHangtag,
      product: product
    });
  };


  render() {
    const { isLoading, hangtag, products, productIsLoading, product, page } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <div className="content content-xs">
            <HangtagComponent
              hangtag={hangtag}
              onClick={this.maintainHangtag}
              onCancel={this.goDevice}
              onChange={this.onChange}
              products={products}
              product={product}
              onInputChangeProduct={this.onInputChangeProduct}
              onChangeOriginalValue={this.onChangeOriginalValue}
              productIsLoading={productIsLoading}
              page={page}
            />
          </div>
        )}
      </>
    );
  }
}

export default HangtagContainer;
