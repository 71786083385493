import React from "react";
import { inject } from "mobx-react";
import DeviceForm from "~/components/DeviceForm";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";
import { TAGS_COUNT_DEFAULT, CHANGE_TIME_PAGE_DEFAULT, SHOW_TIME_MIDIA_DEFAULT, DEVICE_INFO_TAB } from "../../configs/constants";

@inject("DeviceStore", "OrganizationStore", "UserStore")
class NewDeviceContainer extends React.Component {
  constructor(props) {
    super(props);    

    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    await this.getDevice();
  };

  getDevice = async (organizationName) => {
    let device = {};      
    let organizations = await this.getOrganizations(organizationName);
    let organization = organizations[0];

    device.tagCount = TAGS_COUNT_DEFAULT;
    device.changePageTime = CHANGE_TIME_PAGE_DEFAULT;
    device.showMediaTime = SHOW_TIME_MIDIA_DEFAULT;
    device.organizationId = organization.organizationId;
    
    this.setState({
      device,
      organization: organization,
      organizations: organizations,
      isLoading: false,
    });  
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  onChangeDevice = (e) => {
    const { device } = this.state;
    const updatedDevice = {
      ...device,
      [e.target.name]: e.target.value
    }

    this.setState({
      device: updatedDevice,
    });
  };

  onChangeTagCount = (e) => {
    const { device } = this.state;  
    device.tagCount = e.target.value;
    this.setState({
      device: device,
    });
  };

  onKeyUpOrganization = async (value) => {
    try {      
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = (organizationName) => {
    let { organizations, organization } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }
    const { device } = this.state;
    const updatedDevice = {
      ...device,
      organization,
      organizationId: organization?.organizationId,
    }

    this.setState({
      device: updatedDevice,
      organization: organization
    });
  };

  goInsert = async () => {
    this.setState({
      isLoading: true,
    });
    const { DeviceStore } = this.props;
    const { device } = this.state;
    if (device.organizationId === undefined || device.organizationId === 0)
      AlertComponent.error("Estabelecimento inválido");
    else {
      var result = await DeviceStore.insertDevice(device);
      if (result && result?.deviceId > 0){
        this.setState({
          device: result,
        });
        AlertComponent.success("Dispositivo inserido com successo");
        this.goDevice();
      } else {
        AlertComponent.error("Falha ao inserir dispositivo");
      }
    }
    this.setState({
      isLoading: false,
    });
  }

  goDevice = () => {
    const { device } = this.state;
    const { history } = this.props;
    history.push(`device?deviceId=${device.deviceId}&tab=${DEVICE_INFO_TAB}`);
  };

  goDevices = () => {
    const { history } = this.props;
    history.push(`devices`);
  };

  render() {
    const { isLoading, device, organizations, organization } = this.state;
    const { UserStore } = this.props;
    return (
      <>
      {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (    
          <div className="content content-xs">
            <DeviceForm
              device={device}
              onChange={this.onChangeDevice}  
              goUpdate={this.goInsert}      
              onCancel={this.goDevices}
              onChangeTagCount={this.onChangeTagCount}
              onKeyUpOrganization={this.onKeyUpOrganization}        
              onChangeOrganization={this.onChangeOrganization}
              organizations={organizations}
              organization={organization}
              userLevel={UserStore.getLevel()}
            />
          </div>
        )}       
      </>
    );
  }
}

export default NewDeviceContainer;