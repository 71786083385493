import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import UsersComponent from "./UsersComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import PaginationComponent from "~/components/PaginationComponent";
import { getVarFromUrl } from "../../helpers/utils";

@inject("UserStore")
class UsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      users: []
    };
  }

  async componentDidMount() {
    this.getUsers();
  }

  getUsers = async (skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0;
    const { UserStore } = this.props;
    const organizationId = getVarFromUrl("organizationId");
    const data = await UserStore.getUsers(organizationId, skip);
    let userAux = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.users.length > 0) {
      userAux = data.users;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    this.setState({
      users: userAux,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip,
      isLoading: false,
      organizationId: organizationId
    });
  };
 
  goUser = async (userId) => {
    const { history } = this.props;
    history.push(`user?organizationId=${getVarFromUrl("organizationId")}&userId=${userId}`);
  };

  onStatus = async (userId) => {
    this.setState({
      isLoading: true,
    });
    const { UserStore } = this.props;
    const result = await UserStore.statusUpdate(userId);
    this.setState({
      isLoading: false,
    });
    if (result === "true" || result === true) {
      this.getUsers();
      AlertComponent.success("Usuário atualizado com sucesso");
    }
    else AlertComponent.error("Falha ao atualizar usuário"); 
  };

  onPageChange = async (currentPage) => {  
    const skip = (currentPage - 1) * 10;
    await this.getUsers(skip);
  };

  render() {
    const { UserStore } = this.props;
    const { isLoading, users, currentPage, countItems } = this.state;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <div className="content content-xs">
              <Row>             
                <Col style={{textAlign: "right"}}>                  
                  <Button
                      className="btn-fill btn-xs"
                      color="primary"
                      type="submit"
                      onClick={()=>this.goUser(0)}
                    >
                      Novo usuário
                    </Button>                                               
                </Col>           
              </Row>
              <UsersComponent
                userLevel={UserStore.getLevel()}
                users={users}
                onClick={this.goUser}
                onStatus={this.onStatus}
              />
              <PaginationComponent
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={countItems}
                onPageChange={this.onPageChange}
                pageSize={countItems}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default UsersContainer;
