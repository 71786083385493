import { action } from "mobx";
import productService from "~/services/ProductService";

export class ProductStore {

  @action.bound
  getProducts = async (organizationId, filter, skip) => {
    const data = await productService.getAll(organizationId, filter, skip);
    return data;
  };

  @action.bound
  searchProducts = async (organizationId, filter) => {
    const data = await productService.search(organizationId, filter);
    if (!data || data === "" || data.products.length < 1) return [];
    return data.products;
  };

  @action.bound
  searchProductsByDevice = async (filter, deviceId) => {
    const data = await productService.searchByDevice(filter, deviceId);
    if (!data || data === "" || data.products === undefined || data.products === null || data.products.length < 1) {
      let resultData = [];
      let item = {
        productId: 0,
        code: "",
        name: "Nenhum"
      };
      resultData.push(item);
      return resultData;
    } 
    let resultData = [];
    let item = {
      productId: 0,
      code: "",
      name: "Nenhum"
    };
    resultData.push(item);
    resultData.push.apply(resultData, data.products);
    return resultData;
  };

  @action.bound
  getProduct = async (productId) => {
    const data = await productService.getById(productId);
    return data ?? {};
  };

  @action.bound
  maintainProduct = async (product) => {
    if (product?.productId > 0)
      return await productService.update(product);
    
    const result = await productService.insert(product);
    if (result && result?.productId > 0)
      return true;

    return false;
  };

  @action.bound
  uploadFile = async (formData, organizationId) => {
    const data = await productService.uploadFile(formData, organizationId);
    return data ?? false;
  };
  
  @action.bound
  importationStart = async () => {
    const result = await productService.importationStart();
    return result;
  };
  
  @action.bound
  logout = async () => {
    
  }

  @action.bound
  delete = async (productId) => {
    const data = await productService.delete(productId);
    return data;
  }

  @action.bound
  deleteAll = async (organizationId) => {
    const data = await productService.deleteAll(organizationId);
    return data;
  }

  @action.bound
  availableUpdate = async (productId) => {
    return await productService.availableUpdate(productId);
  }  
}

const productStore = new ProductStore();
export default productStore;
