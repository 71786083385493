import React from "react";
import HangtagCard from "~/components/HangtagCard";
import AlertComponent from "~/components/AlertComponent";
import { formatCentsToRealStr }  from "../../../helpers/monetary"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const HangtagsComponent = ({
  hangtags,
  onClick,
  onDelete,
  handleOnDragEnd
}) => {
  return (
    <>
      <div className="content content-xs">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="hangtags">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} style={{padding: "0px"}}>      
                {hangtags?.map((item, index) => {
                  return (       
                    <Draggable key={item.tagId.toString()} draggableId={item.tagId.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <HangtagCard
                            productCode={item.product?.code}
                            productName={((item.product?.description !== null && item.product?.description !== "") ?  item.product?.description : item.product?.name)}
                            productValue={'R$ '.concat(formatCentsToRealStr(item.product?.value))}
                            isOffer={item.isOffer}
                            originalValue={'R$ '.concat(formatCentsToRealStr(item.product?.originalValue))}
                            pageDescription={item.pageDescription}
                            index={index}
                            onClick={() => {
                              onClick(item.tagId);
                            }}
                            onDelete={() => {
                              AlertComponent.dialog("Irá apagar a etiqueta.",
                                function dialoagNo() {
                                },
                                function dialoagYes() {
                                  onDelete(item.tagId);
                                }    
                              );                
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>        
      </div>
    </>
  );
};

export default HangtagsComponent;
