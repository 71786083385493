import React from "react";
import { inject } from "mobx-react";

import SubscriptionComponent from "./SubscriptionComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { valueCurrencyToInt }  from "../../helpers/monetary"
import { dateFormat } from '../../helpers/utils'

@inject("SubscriptionStore", "OrganizationStore")
class SubscriptionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      subscription: {},
    };
  }

  async componentDidMount() {
    await this.getSubscription();
  }

  getSubscription = async () => {
    this.setState({
      isLoading: true,
    });

    const { SubscriptionStore, OrganizationStore, history } = this.props;
    const organizationId = await OrganizationStore.getOrganizationId();
    const organization = await OrganizationStore.getOrganization(organizationId);
    const hasOrganization = organizationId !== undefined && parseInt(organizationId) > 0;
    if(!hasOrganization){
      history.push("/");
      return;
    }

    let subscription = await SubscriptionStore.getSubscriptionByOrganization(organizationId);

    if (subscription === undefined || subscription === null || subscription === "") {
      subscription = {};
      subscription.organizationId = organizationId;
      subscription.valueStr = 0.00.toFixed(2);
    } 
    else {
      subscription.valueStr = (subscription.value / 100.00).toFixed(2);
    }

    subscription.organizationName = organization.name;
    this.setState({
      subscription,
      isLoading: false,
    });
  };

  goOrganization = () => {
    const { history } = this.props;
    history.push(`organizations`);
  };

  maintainSubscription = async () => {
    this.setState({
      isLoading: true
    });

    const { subscription } = this.state;
    const { SubscriptionStore } = this.props;        
   
    const result = await SubscriptionStore.maintainSubscription(subscription);
    if (result && result?.userId > 0){
      AlertComponent.success("Usuário salvo com successo");
      this.goOrganization();
    } 
    else if (result && result.error !== undefined && result.error !== null && result.error !== "") {
      AlertComponent.error(result.error);
    }
    else {
      AlertComponent.error("Falha ao salvar usuário");
    }    

    this.setState({
      isLoading: false
    });
  };

  onChange = (e) => {
    const { subscription } = this.state;
    const updatedSubscription = {
      ...subscription,
      [e.target.name]: e.target.value
    }

    this.setState({
      subscription: updatedSubscription,
    });
  };

  onChangeValue = (e) => {
    const { subscription } = this.state;
    subscription.value = valueCurrencyToInt(e.target.value);
    this.setState({
      subscription
    });
  };

  onChangeDueDate = (e) => {
    const { subscription } = this.state;
    subscription.nextDueDate = dateFormat(e);
    this.setState({
      subscription
    });
  };

  render() {
    const { isLoading, subscription } = this.state;
    return (
      <>
        {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (
          <div className="content content-xs">
            <SubscriptionComponent
              subscription={subscription}
              onClick={this.maintainSubscription}
              onCancel={this.goOrganization}
              onChange={this.onChange}
              onChangeValue={this.onChangeValue}
              onChangeDueDate={this.onChangeDueDate}
            />
          </div>
        )}
      </>
    );
  }
}

export default SubscriptionContainer;
