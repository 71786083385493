import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class ImageService {
  getAll = async (pageId,skip) => {
    const url = `${URL_APP_BASE_PATH}/images?pageId=${pageId}&skip=${skip}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  get = async (imageId) => {
    const url = `${URL_APP_BASE_PATH}/images/${imageId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  delete = async (imageId) => {
    const url = `${URL_APP_BASE_PATH}/images/${imageId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  maintain = async (formData) => {
    try {
      const { data } = await api({
        method: "put",
        url: `${URL_APP_BASE_PATH}/images`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (err) {
      return null;
    }
  };

  reorder = async (images) => {
    const url = `${URL_APP_BASE_PATH}/images/reorder`;
    try {
      const { data } = await api.put(url,images);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const imageService = new ImageService();
export default imageService;
