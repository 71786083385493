import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class DeviceService {
  getAll = async (organizationId, skip) => {
    const url = `${URL_APP_BASE_PATH}/devices/organization/${organizationId}?skip=${skip}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  update = async (device) => {
    const url = `${URL_APP_BASE_PATH}/devices`;
    try {
      const { data } = await api.put(url,device);
      return data;
    } catch (err) {
      return null;
    }
  };

  insert = async (device) => {
    const url = `${URL_APP_BASE_PATH}/devices`;
    try {
      const { data } = await api.post(url,device);
      return data;
    } catch (err) {
      return null;
    }
  };

  assign = async (deviceId, activationCode) => {
    const url = `${URL_APP_BASE_PATH}/activationCode/${activationCode}/assign/${deviceId}`;
    try {
      const { data } = await api.put(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  delete = async (deviceId) => {
    const url = `${URL_APP_BASE_PATH}/devices/${deviceId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getById = async (deviceId) => {
    const url = `${URL_APP_BASE_PATH}/devices/${deviceId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const deviceService = new DeviceService();
export default deviceService;
