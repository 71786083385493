import React from "react";
import { Row, Col } from "reactstrap";
import UserCard from "~/components/UserCard";

const UsersComponent = ({
  userLevel,
  users,
  onClick,
  onStatus
}) => {
  return (
    <>
    <Row>
      <Col>           
        {users.map((item, index) => (
          <UserCard      
            userLevel={userLevel}     
            name={item.name}
            email={item.email}
            status={item.status}
            index={index}
            onClick={() => {
              onClick(item.userId);
            }}
            onStatus={() => {
              onStatus(item.userId);
            }}
          />
        ))}
      </Col>
    </Row>
    </>
  );
};

export default UsersComponent;
