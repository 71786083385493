import { observable, action } from "mobx";
import pageService from "~/services/PageService";

export class PageStore {
  @observable pageId = 0;

  @action.bound
  getPages = async (deviceId,skip) => {
    const data = await pageService.getAllByDevice(deviceId,skip);
    if (data === undefined || data === "") {  
      return [];
    }
    return data;
  };

  @action.bound
  getPage = async (pageId) => {
    const data = await pageService.get(pageId);
    if (data !== undefined && data !== "") {      
      return {...data, pageId: data.pageId};
    }
    return {};
  };

  @action.bound
  setPageId = (pageId) => {
    this.pageId = pageId;
  }

  @action.bound
  getPageId= () => {
    return this.pageId;
  }

  @action.bound
  update = async (page) => {
    const data = await pageService.update(page);
    return data;
  }

  @action.bound
  delete = async (pageId) => {
    const data = await pageService.delete(pageId);
    return data;
  }

  @action.bound
  reorder = async (pages) => {
    const data = await pageService.reorder(pages);
    return data;
  }

  @action.bound
  logout = async () => {
    this.pageId = 0; 
  }
}

const pageStore = new PageStore();
export default pageStore;
