import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { BsFillPencilFill } from "react-icons/bs";
import { cnpjMask } from '../../helpers/utils'
import { ORGANIZATION_STATUS } from '../../configs/constants'
import Switch from '@mui/material/Switch';
import { USER_LEVEL } from "../../configs/constants";

const OrganizationCard = ({  
  userLevel,
  organizationId,
  cnpj,
  name,
  resale,
  status,
  index,
  onClick,
  onStatus
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "0px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>      
        <Col xs={4} md={3} style={{display: "flex", alignItems: "center"}}>
          {cnpjMask(cnpj)}
        </Col>
        <Col xs={4} md={4} style={{display: "flex", alignItems: "center"}}>
           {name}
        </Col>
        <Col md={3} style={{display: "flex", alignItems: "center"}} className="display-none-xs">
           {resale === null ? "Não há" : resale}
        </Col>
        <Col xs={4} md={2} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Switch
            checked={status === ORGANIZATION_STATUS.active}
            onChange={onStatus}
            disabled={userLevel !== USER_LEVEL.manager}
          />
          <BsFillPencilFill              
              size= {20}
              className="icon-xs"
              style={{marginLeft: "5px", marginRight: "5px"}}
              title="Editar"
              onClick={onClick}
              color="#4cd678"
          />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default OrganizationCard;
