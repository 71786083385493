import api from "./api";
import { URL_BRASIL_API_PATH } from "../configs/constants";

export class AddressService {
  getCep = async (zipCode) => {
    const url = `${URL_BRASIL_API_PATH}/cep/v1/${zipCode}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const addressService = new AddressService();
export default addressService;
