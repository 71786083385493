/* eslint-disable no-empty */
/* eslint-disable no-undef */
import { observable, action } from "mobx";
import authService from "../services/AuthService";
import auth from "../services/Auth";
import { TOKEN_KEY, USER_LEVEL } from "../configs/constants";
import userService from "../services/UserService";

export class UserStore {
  @observable loginName = "";
  @observable level;
  @observable name = "";
  @observable users = [];

  getLevel = () => {
    const { level } = auth.getTokenData();
    this.level = level;    
    return parseInt(this.level);
  };

  getName = () => {
    return this.name;
  };

  getToken = () => {
    return auth.getTokenData();
  };

  @action.bound
  login = async (login, password) => {
    const data = await authService.login(login, password);
    if (data && data.accessToken) {
      localStorage.setItem(TOKEN_KEY, data.accessToken);
      this.loginName = login;
      this.level = data.level;
    }
    return data;
  };

  @action.bound
  passwordReset = async (email) => {
    const data = await authService.passwordReset(email);
    return data;
  };

  @action.bound
  changePassword = async (token, password) => {
    const data = await authService.changePassword(token, password);
    return data;
  };

  @action.bound
  isLogged = () => {
    return auth.isAuthenticated();
  };

  @action.bound
  logout = () => {
    this.loginName = "";
    this.level = USER_LEVEL.establishment;
    this.name = "";
    this.users = [];
    auth.logout();
  };

  @action.bound
  getUser = async (userId) => {
    const data = await userService.getById(userId);
    return data ?? {};
  };

  @action.bound
  getUsers = async (organizationId, skip) => {
    this.users = [];
    const data = await userService.getAll(organizationId, skip);
    if (!data || data === "") return this.users;
    this.users = data;
    return this.users;
  };

  @action.bound
  maintainUser = async (user) => {
    if (user?.userId > 0) {
      if (await userService.update(user)) {
        return user;
      }
      else {
        return { userId: 0 };
      }
    }

    return await userService.insert(user);
  };

  @action.bound
  statusUpdate = async (userId) => {
    return await userService.statusUpdate(userId);
  }  
}

const userStore = new UserStore();
export default userStore;
