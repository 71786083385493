import React from "react";
import ReactLoading from "react-loading";
import { Section, SectionMinified } from "./styles";

const LoadingComponent = ({ minified }) =>
  minified === true ? (
    <SectionMinified>
      <ReactLoading type="bars" color="#fff" />
    </SectionMinified>
  ) : (
    <Section>
      <ReactLoading type="bars" color="#fff" />
    </Section>
  );

export default LoadingComponent;
