import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class UserService {
  getAll = async (organizationId, skip) => {
    const url = `${URL_APP_BASE_PATH}/users/organization/${organizationId}?skip=${skip}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getById = async (userId) => {
    const url = `${URL_APP_BASE_PATH}/users/${userId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  insert = async (user) => {
    const url = `${URL_APP_BASE_PATH}/users`;
    try {
      const { data } = await api.post(url, user);
      return data;
    } catch (err) {
      return null;
    }
  };

  update = async (user) => {
    const url = `${URL_APP_BASE_PATH}/users`;
    try {
      const { data } = await api.put(url, user);
      return data;
    } catch (err) {
      return null;
    }
  };

  statusUpdate = async (userId) => {
    try {
      const { data } = await api.put(`${URL_APP_BASE_PATH}/users/status/${userId}`);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const userService = new UserService();
export default userService;
