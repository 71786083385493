import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { dateFormat2, makeFileStatus } from '../../helpers/utils'

const ImportationCard = ({
  register,
  update,
  lines,
  size,
  status,
  index
}) => (
  <Card style={{ cursor: "pointer", marginBottom: "0px" }} className="card-chart">
    <CardBody style={{padding: "8px", paddingLeft: "5px", backgroundColor: (index % 2 === 0 ? "inherit" : "#1e1e2f")}}>
      <Row>
        <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>
           {dateFormat2(register)}
        </Col>
        <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>
           {dateFormat2(update)}
        </Col>
        <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>
           {makeFileStatus(status)}
        </Col> 
        <Col xs={3} md={3} style={{display: "flex", alignItems: "center"}}>
          {lines}
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default ImportationCard;
