import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class FileService {
  getFileBlobs = async (containerName, filter) => {
    let url = `${URL_APP_BASE_PATH}/fileblobs/${containerName}/byFilter`;
    if (filter !== "" && filter !== null && filter !== undefined) url += `?filter=${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getFileBlobsByName = async (containerName, name) => {
    let url = `${URL_APP_BASE_PATH}/fileblobs/${containerName}/byName`;
    if (name !== "" && name !== null && name !== undefined) url += `?fileName=${name}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };  

  isValid = async (url) => {    
    try {
      await api.get(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  importation = async () => {
    const url = `${URL_APP_BASE_PATH}/fileblobs/importation`;
    try {
      const { data } = await api.post(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getByOrganizationId = async (organizationId, skip) => {
    const url = `${URL_APP_BASE_PATH}/files/organization/${organizationId}?skip=${skip}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };  
}

const fileService = new FileService();
export default fileService;