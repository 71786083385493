import React from "react";
import CardDevice from "../../components/CardDevice";
import AlertComponent from "~/components/AlertComponent";

const DevicesComponent = ({
  devices,
  onClick,
  onDelete
}) => {
  return (
    <>        
      {devices.map((item, index) => (
        <CardDevice
          key={index}
          deviceId={item.deviceId}
          title={item.title}
          tagCount={item.tagCount}
          organizationName={item.organizationName}
          index={index}
          onClick={() => {
            onClick(item.deviceId);
          }}
          onDelete={() => {
            AlertComponent.dialog("Irá apagar todas as etiquetas e mídias exibidas neste dispositivo.",
              function dialoagNo() {
              },
              function dialoagYes() {
                onDelete(item.deviceId);
              }    
            );              
          }}
        />
      ))}
    </>
  );
};

export default DevicesComponent;
