import React, { Component } from "react";
import { Provider } from "mobx-react";
import Routes from "./routes";
import userStore from "./stores/UserStore";
import deviceStore from "./stores/DeviceStore";
import productStore from "./stores/ProductStore";
import pageStore from "./stores/PageStore";
import hangtagStore from "./stores/HangtagStore";
import imageStore from "./stores/ImageStore";
import organizationStore from "./stores/OrganizationStore";
import fileStore from "./stores/FileStore";
import addressStore from "./stores/AddressStore";
import fontStore from "./stores/FontStore";
import chargeStore from "./stores/ChargeStore";
import subscriptionStore from "./stores/SubscriptionStore";
import AlertComponent from "./components/AlertComponent";

import "./assets/theme/scss/black-dashboard-react.scss";
import "./assets/theme/css/eprice.css";
import "./assets/theme/demo/demo.css";
import "./assets/theme/css/nucleo-icons.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Provider
        UserStore={userStore}
        DeviceStore={deviceStore}
        PageStore={pageStore}
        HangtagStore={hangtagStore}   
        ProductStore={productStore}  
        ImageStore={imageStore}   
        OrganizationStore={organizationStore}   
        FileStore={fileStore}
        AddressStore={addressStore}
        FontStore={fontStore}
        ChargeStore={chargeStore}
        SubscriptionStore={subscriptionStore}
      >
        <Routes />
        <AlertComponent />
        {/* <PopupComponent /> */}
      </Provider>
    );
  }
}

export default App;
