import React from "react";
import { Row, Col } from "reactstrap";
import ChargeCard from "../../components/ChargeCard";

const ChargesComponent = ({
  charges,
}) => {
  return (
    <>
      <div className="content content-xs">       
        <Row>
          <Col>           
            {charges.map((item, index) => (
              <ChargeCard
                organizationName={item.organization?.name}
                dueDate={item.dueDate}
                value={item.value}
                status={item.status}
                description={item.shortDescription}
                billetView={item.billetView}
                invoiceView={item.invoiceView}
                index={index}
              />
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChargesComponent;
