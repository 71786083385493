import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class OrganizationService {
  getAll = async (filter, addEmptyItem, skip) => {
    if (filter === undefined || filter === null) filter = "";
    let url = `${URL_APP_BASE_PATH}/organizations?filter=${filter}&skip=${skip}`;
    if (addEmptyItem !== undefined) url += `&addEmptyItem=${addEmptyItem}`
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getById = async (organizationId) => {
    const url = `${URL_APP_BASE_PATH}/organizations/${organizationId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  maintain = async (formData) => {
    try {
      const { data } = await api({
        method: "post",
        url: `${URL_APP_BASE_PATH}/organizations`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (err) {
      return null;
    }
  };

  statusUpdate = async (organizationId) => {
    try {
      const { data } = await api.put(`${URL_APP_BASE_PATH}/organizations/status/${organizationId}`);
      return data;
    } catch (err) {
      return null;
    }
  };

}

const organizationService = new OrganizationService();
export default organizationService;
