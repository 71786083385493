/* eslint-disable react/no-string-refs */
/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Button } from "reactstrap";

class AlertComponent extends Component {
  static dialog(message, dialogNo, dialogYes, reconfirm) {
    AlertComponent.__singletonRef.__dialog(message, dialogNo, dialogYes, reconfirm);
  }
  
  static success(message) {
    AlertComponent.__singletonRef.__success(message);
  }

  static error(message) {
    AlertComponent.__singletonRef.__error(message);
  }

  constructor(props) {
    super(props);
    AlertComponent.__singletonRef = this;
  }

  alert(message, type) {
    const options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  alertDialog(message, dialogNo, dialogYes, reconfirm) {    
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-dialog custom-dialog-xs'>
            <div style={{textAlign: "left", fontSize: "18px"}}> 
              <span>Atenção</span> 
            </div>
            <br/>
            <span>{message}</span>
            <br/>
            <br/>
            <Button 
              className="btn-fill btn-xs" 
              onClick={() => {
                dialogNo();
                onClose();
              }}
              color="danger"
              >
                Não
            </Button>        
            <Button 
              className="btn-fill btn-xs" 
              onClick={() => {
                if (reconfirm !== undefined && reconfirm === true) {
                  message += " Esse processo é irreversível, Tem certeza?";
                  this.alertDialog(message, dialogNo, dialogYes, false);
                }
                else {
                  dialogYes(); 
                  onClose();
                }           
              }}
              color="primary"
              >
                Sim
            </Button>
          </div>
        );
      }
    });
  }

  __dialog(message, dialogNo, dialogYes, reconfirm) {
    this.alertDialog(message, dialogNo, dialogYes, reconfirm);
  }
 
  __success(message) {
    this.alert(message, "success");
  }

  __error(message) {
    this.alert(message, "danger");
  }

  render() {
    return (
      <div className="react-notification-alert-container">
        <NotificationAlert ref="notificationAlert" />
      </div>
    );
  }
}

export default AlertComponent;
