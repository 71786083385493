import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import auth from "./services/Auth";
import LoginContainer from "./pages/Login";
import HomeContainer from "./pages/Home";
import ChangePasswordContainer from "./pages/ChangePassword";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated() ? (
        <Component {...props} exact/>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>      
      <Route exact path="/passwordReset" component={ChangePasswordContainer} />
      <Route exact path="/" component={LoginContainer} />
      <PrivateRoute path="/home" component={HomeContainer} />
      <Route path="*" component={HomeContainer} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
