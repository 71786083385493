import { observable, action } from "mobx";
import fontService from "../services/FontService";

export class FontStore {
  @observable fonts = [];

  @action.bound
  getFonts = async (filter) => {
    if (this.fonts != null && this.fonts.length > 0) return this.fonts;
    const data = await fontService.getFonts(filter);
    if (data === null || data === "" || data === undefined) return null;
    let resultData = [];
    let item = {
      id: 0,
      name: "Nenhum"
    };
    resultData.push(item);
    resultData.push.apply(resultData, data)
    this.fonts = resultData;
    return this.fonts;
  };

  @action.bound
  logout = () => {
    this.fonts = [];
  };
}

const fontStore = new FontStore();
export default fontStore;
