import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class ProductService {
  getAll = async (organizationId, filter, skip) => {
    let url = `${URL_APP_BASE_PATH}/products?organizationId=${organizationId}&skip=${skip}`;
    if (filter !== undefined && filter !== "" && filter !== null) url += `&filter=${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
  
  search = async (organizationId, filter) => {
    let url = `${URL_APP_BASE_PATH}/products?organizationId=${organizationId}`;
    if (filter !== undefined && filter !== "" && filter !== null) url += `&filter=${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  searchByDevice = async (filter, deviceId) => {
    let url = `${URL_APP_BASE_PATH}/products?deviceId=${deviceId}`;
    if (filter !== undefined && filter !== null && filter !== "") url += `&filter=${filter}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getById = async (productId) => {
    const url = `${URL_APP_BASE_PATH}/products/${productId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  insert = async (product) => {
    const url = `${URL_APP_BASE_PATH}/products`;
    try {
      const { data } = await api.post(url, product);
      return data;
    } catch (err) {
      return null;
    }
  };

  update = async (product) => {
    const url = `${URL_APP_BASE_PATH}/products`;
    try {
      const { data } = await api.put(url, product);
      return data;
    } catch (err) {
      return null;
    }
  };

  uploadFile = async (formData, organizationId) => {
    try {
      const { data } = await api({
        method: "post",
        url: `${URL_APP_BASE_PATH}/products/upload/${organizationId}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (err) {
      return null;
    }
  };

  importationStart = async () => {
    const url = `${URL_APP_BASE_PATH}/importation`;
    try {
      const { data } = await api.put(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  delete = async (produtId) => {
    const url = `${URL_APP_BASE_PATH}/products/${produtId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  deleteAll = async (organizationId) => {
    const url = `${URL_APP_BASE_PATH}/products/organization/${organizationId}`;
    try {
      const { data } = await api.delete(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  availableUpdate = async (productId) => {
    try {
      const { data } = await api.put(`${URL_APP_BASE_PATH}/products/status/${productId}`);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const productService = new ProductService();
export default productService;
