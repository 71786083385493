import React from "react";
import { inject } from "mobx-react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { DEVICE_INFO_TAB, PAGES_TAB } from "~/configs/constants";
import LoadingComponent from "~/components/LoadingComponent";
import classnames from "classnames";
import DeviceTab from "./DeviceTab";
import Pages from "./PagesTab";
import { getVarFromUrl } from "../../helpers/utils";

@inject("DeviceStore")
class DeviceModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: DEVICE_INFO_TAB,
      isLoading: true
    };
  }

  async componentDidMount() {
    const { activeTab } = this.state;
    const device = getVarFromUrl("deviceId");
    const tab = getVarFromUrl("tab");
    await this.validateDevice(device);
    this.setState({
      activeTab: (tab !== undefined  && tab !== null ? tab : activeTab),
      isLoading: false
    });
  }

  validateDevice = async (device) => {
    const { DeviceStore } = this.props;
    DeviceStore.setDeviceId(device);
    var validDevice = await DeviceStore.validateDevice();
    if (!validDevice){
      const { history } = this.props;
      history.push(`devices`);
    }
  }

  tabToggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab === tab) return;
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { activeTab, isLoading } = this.state;
    const { history } = this.props;
    return (
      <>
      {(isLoading && (
          <>
            <LoadingComponent />
          </>
        )) || (      
          <div className="content content-xs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === DEVICE_INFO_TAB })}
                  onClick={() => {
                    this.tabToggle(DEVICE_INFO_TAB);
                  }}
                >
                  Dispositivo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === PAGES_TAB })}
                  onClick={() => {
                    this.tabToggle(PAGES_TAB);
                  }}
                >
                  Páginas
                </NavLink>
              </NavItem>              
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={DEVICE_INFO_TAB}>
                <DeviceTab history={history} />
              </TabPane>
              <TabPane tabId={PAGES_TAB}>
                <Pages history={history} />
              </TabPane>
            </TabContent>
          </div>
        )}
      </>
    );
  }
}

export default DeviceModule;
