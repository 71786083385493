import { action } from "mobx";
import fileService from "../services/FileService";
import { FILE_CONTAINER } from "../configs/constants";

export class FileStore {

  @action.bound
  getDesigns = async (filter) => {
    const data = await fileService.getFileBlobs(FILE_CONTAINER.designs, filter);
    if (data === null || data === "" || data === undefined) return null;
    let resultData = [];
    let item = {
      id: 0,
      url: "",
      name: "Nenhum"
    };
    resultData.push(item);
    resultData.push.apply(resultData, data)
    return resultData;
  };

  @action.bound
  getImages = async (filter) => {
    let resultData = [];
    let item = {
      id: 0,
      url: "",
      description: "Nenhum"
    };
    resultData.push(item);
    const data = await fileService.getFileBlobs(FILE_CONTAINER.images, filter);
    if (data === null || data === "" || data === undefined) return resultData;   
    resultData.push.apply(resultData, data)
    return resultData
  };

  @action.bound
  getImagesByName = async (name) => {
    let resultData = [];
    let item = {
      id: 0,
      url: "",
      description: "Nenhum"
    };
    resultData.push(item);
    let data = await fileService.getFileBlobsByName(FILE_CONTAINER.images, name);
    if (data === null || data === "" || data === undefined) return resultData;  
    resultData.push.apply(resultData, data)    
    return resultData;
  };

  @action.bound
  isValid = async (url) => {
    return await fileService.isValid(url);
  };

  @action.bound
  importation = async () => {
    return await fileService.importation();
  };  

  @action.bound
  logout = () => {
  };

  @action.bound
  getByOrganizationId = async (organizationId, skip) => {
    return await fileService.getByOrganizationId(organizationId, skip);
  };  

}

const fileStore = new FileStore();
export default fileStore;
