import { action } from "mobx";
import imageService from "~/services/ImageService";

export class ImageStore {
  @action.bound
  getImages = async (pageId, skip) => {
    const data = await imageService.getAll(pageId, skip);    
    if (data === undefined || data === "") return null;
    return data
  };

  @action.bound
  getImage = async (imageId) => {
    const data = await imageService.get(imageId);
    if (data && data !== "") {
      return data;
    }
    return {};
  };

  @action.bound
  maintainImage = async (formData) => {   
    const result = await imageService.maintain(formData);
    if (result && result?.imageId > 0)
      return "";
    if (result && result.error !== undefined && result.error !== null && result.error !== "")
      return result.error;
    return "Falha ao salvar mídia";
  }

  @action.bound
  deleteImage = async (imageId) => {
    const data = await imageService.delete(imageId);
    return data;
  }

  @action.bound
  reorder = async (images) => {
    const data = await imageService.reorder(images);
    return data;
  }

  @action.bound
  logout = async () => {
     
  }
}

const imageStore = new ImageStore();
export default imageStore;
