import { action } from "mobx";
import subscriptionService from "~/services/SubscriptionService";

export class SubscriptionStore {

  @action.bound
  getSubscriptionByOrganization = async (organizationId) => {
    const data = await subscriptionService.getByOrganization(organizationId);
    return data ?? {};
  };

  @action.bound
  maintainSubscription = async (subscription) => {
    return await subscriptionService.maintain(subscription);
  }; 
}

const subscriptionStore = new SubscriptionStore();
export default subscriptionStore;
