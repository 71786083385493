import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { Section } from "./styles";

const LoginComponent = ({
  onChange,
  goLogin,
  login,
  password,
  setForgotPassword,
  logo
}) => {
  return (
    <Section>
      <div className="content content-xs">
        <Row>
          <Col>
            <Card>
              <CardHeader>
              <Row>
                <Col xs={6} md={6}>
                  <h5 className="title">Bem vindo</h5>
                </Col>
                <Col xs={6} md={6}>
                  <a href={logo.outterLink} target="_blank" rel="noopener noreferrer"><img src={logo.imgSrc} alt="react-logo" style={{width: "80px"}}/></a>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    goLogin();
                  }}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>E-mail</label>
                        <Input
                          id="login"
                          name="login"
                          value={login}
                          onChange={(e) => onChange(e)}
                          placeholder="e-mail"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Senha</label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Senha"
                          value={password}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>                 
                  <Row>
                    <Col style={{textAlign: "right"}}>
                      <Button
                        className="btn-fill btn-xs"
                        color="primary"
                        type="submit"
                      >
                        Entrar
                      </Button>
                    </Col>
                  </Row>                 
                  { <Row>
                    <Col>
                      <Button
                        color="link"
                        className="btn-fill btn-xs"
                        style={{ color: "#fff", opacity: "0.5" }}
                        onClick={setForgotPassword}
                      >
                        Esqueci minha senha
                      </Button>
                    </Col>
                  </Row>                   }                   
                </Form>               
              </CardBody>
              <Row>
                <Col style={{textAlign: "center", color: "white"}}>                
                  <h5 className="nav-link" style={{marginBottom: "0px"}}>Versão: {process.env.REACT_APP_VERSION_CODE}</h5>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Section>
  );
};

LoginComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  goLogin: PropTypes.func.isRequired,
  login: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default LoginComponent;
