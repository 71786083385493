import api from "./api";
import { URL_APP_BASE_PATH } from "../configs/constants";

export class ChargeService {
  get = async (organizationId, dueDateStart, dueDateEnd, status, skip) => {
    let url = `${URL_APP_BASE_PATH}/charges?skip=${skip}`;
    if (organizationId !== undefined && organizationId > 0)
      url += `&organizationId=${organizationId}`;
    if (dueDateStart !== undefined && dueDateStart !== null && dueDateStart !== "")
      url += `&dueDateStart=${dueDateStart}`;
    if (dueDateEnd !== undefined && dueDateEnd !== null && dueDateEnd !== "")
      url += `&dueDateEnd=${dueDateEnd}`;
    if (status !== undefined && status !== null && status !== "")
      url += `&status=${status}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

}

const chargeService = new ChargeService();
export default chargeService;
