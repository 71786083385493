import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { cnpjMask, phoneMask } from '../../helpers/utils'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'

const OrganizationComponent = ({
  onChange,
  onChangeImportation,
  onFileChange,
  onClick,
  onCancel,
  organization,
  onChangeAddress,
  onKeyUpOrganizationFather,
  onChangeOrganizationFather,
  organizationsFather,
  organizationFather
}) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <h5 className="title">Estabelecimento</h5>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>CNPJ</label>
                    <Input
                      name="cnpj"
                      type="text"
                      placeholder="CNPJ"
                      value={cnpjMask(organization?.cnpj)}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Nome</label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Nome"
                      value={organization?.name}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Telefone</label>
                    <Input
                      name="phone"
                      type="tel"
                      maxLength="15"
                      placeholder="Telefone"
                      value={phoneMask(organization?.phone)}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Celular</label>
                    <Input
                      name="mobilePhone"
                      type="tel"
                      maxLength="15"
                      placeholder="Celular"
                      value={phoneMask(organization?.mobilePhone)}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>E-mails</label>
                    <Input
                      name="billingEmails"
                      type="text"
                      placeholder="E-mails"
                      value={organization?.billingEmails}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <label>Responsável</label>
                    <Input
                      name="contactName"
                      type="text"
                      placeholder="Responsável"
                      value={organization?.contactName}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>              
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <label style={{marginBottom: "0px"}}>Revenda</label>
                      <Autocomplete
                        noOptionsText={"Nenhum item"}
                        onKeyUp={(event, value) =>   
                          onKeyUpOrganizationFather(document.getElementById("organizationFather").value)
                        }                            
                        onChange={(event, value) =>                         
                          onChangeOrganizationFather(value)
                        }
                        style={{paddingBottom: "10px"}}                                      
                        value={organizationFather?.name}
                        id="organizationFather"
                        options={organizationsFather.map((organizationFather) => organizationFather.name)}
                        isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params} variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              {option}
                            </span>
                          );
                        }}                      
                      />
                    </FormGroup>
                  </Col>                      
                </Row>
              <Row>                
                <Col xs={12} md={4}>
                  <FormGroup>
                    <label>CEP</label><br/>
                    <Input id="zipcode" name="zipcode" type="text" maxLength={10} value={organization?.address?.zipcode} onChange={(e) => onChangeAddress(e)} />
                  </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                  <FormGroup>
                    <label>Rua</label><br/>
                    <Input id="street" name="street" type="text" value={organization?.address?.street} onChange={(e) => onChangeAddress(e)} />
                  </FormGroup>
                </Col>                
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup>                
                    <label>Bairro</label><br/>
                    <Input id="neighborhood" name="neighborhood" type="text" value={organization?.address?.neighborhood} onChange={(e) => onChangeAddress(e)} />  
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <label>Cidade</label><br/>
                    <Input id="city" name="city" type="text" value={organization?.address?.city} onChange={(e) => onChangeAddress(e)} />
                  </FormGroup> 
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>                
                    <label>Estado</label><br/>
                    <Input id="state" name="state" type="text" value={organization?.address?.state} onChange={(e) => onChangeAddress(e)} />  
                  </FormGroup>
                </Col>                
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>               
                    <label>Número</label><br/>
                    <Input id="number" name="number" type="text" value={organization?.address?.number} onChange={(e) => onChangeAddress(e)} />
                  </FormGroup>  
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>               
                    <label>Complemento</label><br/>
                    <Input id="complement" name="complement" type="text" value={organization?.address?.complement} onChange={(e) => onChangeAddress(e)} />
                  </FormGroup>  
                </Col>                
              </Row>
              <Row>
                <Col xs={12} md={6}>                
                  <label>A extensão suportada é .PNG</label><br/>
                  <input id="uploadCaptureInputLogo" type="file" onChange={onFileChange} />  
                </Col>
                <Col xs={12} md={6}>
                  <img alt="" src={organization?.logoUrl} style={{maxWidth: "200px", maxHeight: "200px"}}/>  
                </Col> 
              </Row> 
              {/* <Row>
                <Col>
                  <FormGroup>
                    <label>Token de integração (Ao alterar este dado se algum sistema estiver integrado deve alterar essa chave no sistema também)</label>
                    <Input
                      name="identifier"
                      type="text"
                      placeholder="Token de integração"
                      value={organization?.identifier}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>    
              <Row>
                <Col>
                  <FormGroup>
                    <label>Ativar integração</label><br/>
                    <ButtonGroup>
                      <Button color="info" className={classNames("btn-simple", {active: organization?.importation?.actived === false})} outline onClick={()=>onChangeImportation(mountEvent("actived", false))} >Não</Button>
                      <Button color="info" className={classNames("btn-simple", {active: organization?.importation?.actived === true})} outline onClick={()=>onChangeImportation(mountEvent("actived", true))} >Sim</Button>
                    </ButtonGroup>                        
                  </FormGroup>
                </Col>
              </Row> 
              <Row>
                <Col>
                  <FormGroup>
                    <label>Tipo de integração</label><br/>
                    <ButtonGroup>
                      <Button color="info" className={classNames("btn-simple", {active: organization?.importation?.type === 1})} outline onClick={()=>onChangeImportation(mountEvent("type", 1))} >Sg</Button>
                    </ButtonGroup>                        
                  </FormGroup>
                </Col>
              </Row>          
              <Row>
                <Col>
                  <FormGroup>
                    <label>Link da API</label>
                    <Input
                      name="baseUrl"
                      type="text"
                      placeholder="https://exemplo.com"
                      value={organization?.importation?.baseUrl}
                      onChange={(e) => onChangeImportation(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>     
              <Row>
                <Col>
                  <FormGroup>
                    <label>Usuário da API</label>
                    <Input
                      name="username"
                      type="text"
                      placeholder="Nome de usuário"
                      value={organization?.importation?.username}
                      onChange={(e) => onChangeImportation(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>   
              <Row>
                <Col>
                  <FormGroup>
                    <label>Senha da API</label>
                    <Input
                      name="password"
                      type="text"
                      placeholder="Senha do usuário da API"
                      value={organization?.importation?.password}
                      onChange={(e) => onChangeImportation(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>     
              <Row>
                <Col>
                  <FormGroup>
                    <label>Filial ID</label>
                    <Input
                      name="branchId"
                      type="text"
                      placeholder="Identificador da filial"
                      value={organization?.importation?.branchId}
                      onChange={(e) => onChangeImportation(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>       */}
              <Row>
                <Col style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="danger"
                    type="button"
                    onClick={()=>onCancel()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>                  
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

OrganizationComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired
};

export default OrganizationComponent;