import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CurrencyInput from 'react-currency-input-field';
import { autoCompleteSx } from '../../helpers/utils'

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";

function mountEvent(name,value) {
  return {
    target:{
      name: name,
      value: value,
    }
  };
};

const HangtagComponent = ({
  onChangeOriginalValue,
  onChange,
  onClick,
  onCancel,
  hangtag,  
  products,
  product,
  onInputChangeProduct,
  productIsLoading,
  page,
}) => {
  return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">Etiqueta</h5>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onClick();
                  }}
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Dispositivo</span>
                        <Input
                          id="deviceId"
                          name="deviceId"
                          value={hangtag?.deviceId}
                          placeholder="Dispositivo"
                          type="text"
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Página</span>
                        <Input
                          id="pageDescription"
                          name="pageDescription"
                          value={page?.description}
                          placeholder="Página"
                          type="text"
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>               
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span style={{width: "100%"}}>Buscar produto</span>
                        <Autocomplete
                          noOptionsText={"Nenhum"}
                          onInputChange={(event, value) =>                         
                            onInputChangeProduct(event, value)
                          }
                          loadingText={"Carregando"}
                          loading={productIsLoading}
                          style={{paddingBottom: "10px"}}   
                          id="product"
                          value={product}               
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={products.map((product) => product)}
                          size="small"
                          renderInput={(params) => (
                            <TextField  {...params}  variant='standard'/>
                          )}
                          sx={autoCompleteSx}
                          renderOption={(props, option) => {
                            return (
                              <span  {...props}>
                                <Row>
                                  <Col xs={12} md={12} style={{textAlign: "left"}}>
                                    {option.name}
                                  </Col>                              
                                </Row>
                              </span>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Codigo do produto</span>
                        <Input
                          name="productCode"
                          type="number"
                          placeholder="Produto"
                          value={hangtag?.product?.code}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Produto</span>
                        <Input
                          name="productName"
                          type="text"
                          placeholder="Produto"
                          value={hangtag?.product?.name}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Valor</span>
                        <Input
                          name="productName"
                          type="text"
                          placeholder="Produto"
                          value={hangtag?.product?.valueStr}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                        <FormGroup>
                          <span>Nome do produto que vai aparecer na etiqueta, se vazio apresenta o nome acima</span>
                          <Input
                            name="customDescription"
                            type="text"
                            placeholder="Ex: Morango"
                            value={hangtag?.customDescription}
                            onChange={(e) => onChange(e)}
                          />
                      </FormGroup>
                    </Col>                    
                  </Row>         
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>É promoção</span>
                        <ButtonGroup style={{width: "100%"}}>
                          <Button color="info" className={classNames("btn-simple", {active: hangtag?.isOffer === true})} outline onClick={()=>onChange(mountEvent("isOffer", true))} >Sim</Button>
                          <Button color="info" className={classNames("btn-simple", {active: hangtag?.isOffer === false})} outline onClick={()=>onChange(mountEvent("isOffer", false))} >Não</Button>
                        </ButtonGroup>                            
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <span>Valor original, sem oferta</span>
                        <CurrencyInput
                            className="form-control"
                            defaultValue={hangtag?.product?.originalValueStr}
                            name="originalValueStr"
                            decimalsLimit="2"             
                            placeholder="0,00"
                            onChange={onChangeOriginalValue}
                        />
                      </FormGroup>
                    </Col>
                  </Row>    
                  <Row>
                    <Col style={{textAlign: "right"}}>                    
                      <Button
                        className="btn-fill btn-xs"
                        color="danger"
                        type="button"
                        onClick={()=>onCancel()}
                      >
                        Voltar
                      </Button>
                      <Button
                        className="btn-fill btn-xs"
                        color="primary"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>                  
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
  );
};

HangtagComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  hangtag: PropTypes.object.isRequired
};

export default HangtagComponent;