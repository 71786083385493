import AssignDeviceContainer from "../pages/AssignDevice";
import NewDeviceContainer from "../pages/NewDevice";
import OrganizationsContainer from "../pages/Organizations";
import OrganizationContainer from "../pages/Organization";
import DevicesContainer from "../pages/Devices";
import ProductsContainer from "../pages/Products";
import ProductContainer from "../pages/Product";
import ChangePasswordContainer from "../pages/ChangePassword";
import PageContainer from "../pages/Page";
import HangtagContainer from "../pages/Hangtag";
import ImageContainer from "../pages/Image";
import DeviceModule from "../pages/DeviceModule/DeviceModule";
import UsersContainer from "../pages/Users"
import UserContainer from "../pages/User";
import ChargesContainer from "../pages/Charges"
import ImportationsContainer from "../pages/Importations"
import SubscriptionContainer from "../pages/Subscription/SubscriptionContainer";

export const getMenus = (userLevel) => {
  let menus = [
    {
      path: "/connect-device",
      name: "Inicio",
      icon: "tim-icons icon-app",
      component: AssignDeviceContainer,
      layout: "/home",
    },
    {
      path: "/devices",
      name: "Dispositivos",
      icon: "tim-icons icon-tablet-2",
      component: DevicesContainer,
      layout: "/home",
    },
    {
      path: "/products",
      name: "Produtos",
      icon: "tim-icons icon-cart",
      component: ProductsContainer,
      layout: "/home",
    },
    {
      path: "/organizations",
      name: "Estabelecimentos",
      icon: "tim-icons icon-bank",
      component: OrganizationsContainer,
      layout: "/home",
      hidden: false
    },
    {
      path: "/importations",
      name: "Importações",
      icon: "tim-icons icon-bank",
      component: ImportationsContainer,
      layout: "/home",
      hidden: false
    },
    {
      path: "/charges",
      name: "Cobranças",
      icon: "tim-icons icon-bank",
      component: ChargesContainer,
      layout: "/home",
      hidden: false
    },
    {
      path: "/users",
      name: "Usuários",
      icon: "tim-icons icon-bank",
      component: UsersContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/device",
      name: "Dispositivo",
      icon: "tim-icons icon-cart",
      component: DeviceModule,
      layout: "/home",
      hidden: true
    },
    {
      path: "/new-device",
      name: "Novo Dispositivo",
      icon: "tim-icons icon-cart",
      component: NewDeviceContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/product",
      name: "Produto",
      icon: "tim-icons icon-cart",
      component: ProductContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/organization",
      name: "Loja",
      icon: "tim-icons icon-cart",
      component: OrganizationContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/page",
      name: "Página",
      icon: "tim-icons icon-cart",
      component: PageContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/hangtag",
      name: "Etiqueta",
      icon: "tim-icons icon-cart",
      component: HangtagContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/image",
      name: "Mídias",
      icon: "tim-icons icon-cart",
      component: ImageContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/changePassword",
      name: "Alterar senha",
      icon: "tim-icons icon-key-25",
      component: ChangePasswordContainer,
      layout: "/home",
      hidden: true,
    },
    {
      path: "/user",
      name: "Novo Usuário",
      icon: "tim-icons icon-cart",
      component: UserContainer,
      layout: "/home",
      hidden: true
    },
    {
      path: "/subscription",
      name: "Mensalidade",
      icon: "tim-icons icon-cart",
      component: SubscriptionContainer,
      layout: "/home",
      hidden: true
    },
  ];

  return menus;
};
