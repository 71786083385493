import React from "react";
import { Row, Col } from "reactstrap";
import ProductCard from "~/components/ProductCard";
import { formatCentsToRealStr }  from "../../helpers/monetary"
import AlertComponent from "~/components/AlertComponent";

const ProductsComponent = ({
  products,
  onClick,
  onDelete,
  onAvailable
}) => {
  return (
    <>    
    <Row>
      <Col>               
        {products.map((item, index) => (
          <ProductCard
            key={index}
            code={item.code}
            name={item.name}
            available={item.available}
            value={'R$ '.concat(formatCentsToRealStr(item.value))}
            index={index}
            onClick={() => {
              onClick(item.productId);
            }}
            onDelete={() => {
              AlertComponent.dialog("Irá apagar todas as etiquetas e mídias vinculadas com este produto.",
                function dialoagNo() {
                },
                function dialoagYes() {
                  onDelete(item.productId);
                }    
              );              
            }}
            onAvailable={() => {
              onAvailable(item.productId);              
            }}
          />
        ))}
      </Col>      
    </Row>
    </>
  );
};

export default ProductsComponent;
